import * as XLSX from "xlsx";
import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { useJobs } from "../../hooks/useJobs/useJobs";
import { debounce } from "lodash";
import SpeakersList from "../SpeakersList/SpeakersList";

const SpeakersChart = ({
  selectedJobId,
  generalSelectedProgram,
  selectedProgram,
}) => {
  const { getJobs, getJobById, transformData } = useJobs();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(selectedJobId);
  const [selectedGender, setSelectedGender] = useState("general");
  const [chartKey, setChartKey] = useState(0);
  const chartDataRef = useRef([]);
  const removedBarsRef = useRef([]);
  const removedBarsSetRef = useRef(new Set());
  const originalChartDataRef = useRef([]);
  const [pieData, setPieData] = useState([]);
  const [dataWithColors, setDataWithColors] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showExcel, setShowExcel] = useState(false);
  const [removedBarsGlobal, setRemovedBarsGlobal] = useState([]);
  const globalRemovedBarsRef = useRef([]);
  const allOriginalDataRef = useRef([]);
  const totalProgramPresence = useRef(null);
  //estado calcular el tiempo total del programa
  const [totalProgramTime, setTotalProgramTime] = useState(0);

  const optionsRef = useRef({
    series: [],
    chart: {
      height: 500,
      type: "bar",
      events: {
        dataPointSelection: debounce((event, chartContext, config) => {
          handleBarClick(config);
        }, 300),
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        barHeight: "70%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: [],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
    },
    title: {
      text: "Presència Speaker",
      floating: false,
      offsetY: 0,
      align: "center",
      style: {
        color: "#444",
      },
    },
  });

  const pieOptionsRef = useRef({
    chart: {
      toolbar: {
        show: true,
      },
      type: "pie",
      width: 380,
    },
    labels: [],
    title: {
      text: "Distribució de Presència",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return parseFloat(val.toFixed(2)) + "%";
        },
      },
    },
  });

  const filteredJobs = jobs.filter((job) =>
    job.titol.toLowerCase().includes(filterText)
  );


  const fetchData = useCallback(async () => {
    if (!selectedJob) return;
    try {
      const job = await getJobById(selectedJob);
      const transformedData = transformData(job);
      const speakerNames = Object.keys(transformedData);
  
      const totalProgramTime = calculateTotalTimeProgram(job);
  
      const allData = speakerNames.map((speaker, index) => {
        const { gender, totalTime = 0 } = transformedData[speaker] || {};
        const genderLabel = gender.charAt(0).toUpperCase();
        const color = gender.toLowerCase() === "male" ? "#008ffb" : "#00e396";
  
        return {
          speakerId: `S${speaker}`,
          name: `S${speaker} (${genderLabel})`,
          totalTime,
          gender: gender.toLowerCase(),
          color: color,
          index: index,
        };
      });

      allOriginalDataRef.current = [...allData];
  
      const filteredData = allData.filter((speaker) => {
        const isGenderMatch =
          selectedGender === "general" ||
          speaker.gender === selectedGender.toLowerCase();
        const isNotRemoved = !globalRemovedBarsRef.current.some(
          (bar) => bar.name === speaker.name.split(" ")[0]
        );
        return isGenderMatch && isNotRemoved;
      });
  
      const recalculatedData = recalculatePercentages(filteredData, selectedGender);
      
      originalChartDataRef.current = recalculatedData;
      chartDataRef.current = recalculatedData;
  
      const seriesData = recalculatedData.map(({ name, data, color }) => ({
        x: name,
        y: data,
        fillColor: color,
      }));
  
      optionsRef.current = {
        ...optionsRef.current,
        series: [
          {
            name: "Presence",
            data: seriesData,
          },
        ],
        xaxis: {
          ...optionsRef.current.xaxis,
          categories: recalculatedData.map(({ name }) => name),
        },
      };
  
      setPieData(recalculatedData.map((item) => item.data));
      pieOptionsRef.current = {
        ...pieOptionsRef.current,
        labels: recalculatedData.map(({ name }) => name),
      };
  
      setChartKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error fetching or transforming data:", error);
    }
  }, [selectedJob, selectedGender, getJobById, transformData]);
  
  


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobsData = await getJobs();
        setJobs(jobsData);
        if (jobsData.length > 0 && !selectedJobId) {
          setSelectedJob(jobsData[0].id);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, [getJobs, selectedJobId]);

  useEffect(() => {
    if (selectedProgram) {
      const filtered = jobs.filter((job) => job.titol === selectedProgram);
      if (filtered.length > 0) {
        setSelectedJob(filtered[0].id);
      } else {
        setSelectedJob(null);
      }

      removedBarsRef.current = [];
      globalRemovedBarsRef.current = [];
      setRemovedBarsGlobal([]);
    }
  }, [selectedProgram, jobs]);

  const handleChangeJob = useCallback((e) => {
    const newSelectedJob = e.target.value;

    setSelectedJob(newSelectedJob);

    removedBarsRef.current = [];
    globalRemovedBarsRef.current = [];
    setRemovedBarsGlobal([]);

    fetchData();
  }, []);

  const handleChangeGender = useCallback((event) => {
    setSelectedGender(event.target.value);
  }, []);



  const recalculatePercentages = (data, selectedGender) => {
    let totalVisible = 0;
  
    if (selectedGender === 'male') {
      totalVisible = data.filter(item => item.gender === 'male')
                          .reduce((sum, item) => sum + (item.totalTime || 0), 0);
    } else if (selectedGender === 'female') {
      totalVisible = data.filter(item => item.gender === 'female')
                          .reduce((sum, item) => sum + (item.totalTime || 0), 0);
    } else {
      totalVisible = data.reduce((sum, item) => sum + (item.totalTime || 0), 0);
    }
  
    const recalculatedData = data.map((item) => ({
      ...item,
      data: totalVisible > 0 ? (item.totalTime / totalVisible) * 100 : 0,
    }));
  
    recalculatedData.sort((a, b) => a.index - b.index);
  
    const totalPercentage = recalculatedData.reduce((sum, item) => sum + item.data, 0);
    console.log(`Total recalculated percentage for ${selectedGender} data: ${totalPercentage.toFixed(2)}%`);
  
    return recalculatedData;
  };
  
  const handleBarClick = useCallback(
    (config) => {
      const { dataPointIndex, w } = config;
  
      if (!w?.config?.series?.[0]?.data?.[dataPointIndex]) {
        console.error("Data is not available for the click event.");
        return;
      }
  
      const clickedData = w.config.series[0].data[dataPointIndex];
      const barNameBase = clickedData.x.split(" ")[0];
  
      const originalData = originalChartDataRef.current.find(
        (item) => item.name.split(" ")[0] === barNameBase
      );
  
      if (!originalData) {
        console.error("Original data not found");
        return;
      }
  
      const removedBar = {
        name: barNameBase,
        gender: originalData.gender,
        viewWhenRemoved: selectedGender,
        originalIndex: originalData.index,
      };
  
      if (
        !removedBarsRef.current.some(
          (bar) =>
            bar.name === barNameBase && bar.gender === originalData.gender
        )
      ) {
        removedBarsRef.current.push(removedBar);
        globalRemovedBarsRef.current.push(removedBar);
      }
  
      const updatedData = chartDataRef.current.filter(
        (item) => item.name.split(" ")[0] !== barNameBase
      );
  
      if (updatedData.length === 0) {
        console.warn("No data remaining after filtering.");
        return;
      }
  
      const recalculatedData = recalculatePercentages(updatedData, selectedGender);
  
      chartDataRef.current = recalculatedData;
  
      const updatedSeries = recalculatedData.map(({ name, data, color }) => ({
        x: name,
        y: data,
        fillColor: color,
      }));
  
      optionsRef.current = {
        ...optionsRef.current,
        series: [
          {
            name: "Presence",
            data: updatedSeries,
          },
        ],
        xaxis: {
          ...optionsRef.current.xaxis,
          categories: recalculatedData.map(({ name }) => name),
        },
      };
  
      setPieData(recalculatedData.map((item) => item.data));
      pieOptionsRef.current = {
        ...pieOptionsRef.current,
        labels: recalculatedData.map(({ name }) => name),
      };
  
      setChartKey((prevKey) => prevKey + 1);
    },
    [selectedGender]
  );
  


  const handleRestoreBar = useCallback(
    (barNameBase) => {
  
      const removedBar = globalRemovedBarsRef.current.find(
        (bar) => bar.name === barNameBase
      );
  
      if (!removedBar) {
        console.error("No se encontró información de la barra eliminada");
        return;
      }
  
      const dataToRestore = allOriginalDataRef.current.find(
        (item) => item.speakerId === barNameBase
      );
  
      if (!dataToRestore) {
        console.error("No se encontraron los datos originales para restaurar");
        return;
      }
  
  
      const combinedData = [...chartDataRef.current, dataToRestore];
  
      const orderedData = allOriginalDataRef.current.filter((originalItem) =>
        combinedData.some((item) => item.speakerId === originalItem.speakerId)
      );
  
      chartDataRef.current = orderedData;
  
      globalRemovedBarsRef.current = globalRemovedBarsRef.current.filter(
        (bar) => bar.name !== barNameBase
      );
      removedBarsRef.current = removedBarsRef.current.filter(
        (bar) => bar.name !== barNameBase
      );
  
  
      let recalculatedData = [];
      if (selectedGender === "general") {
        recalculatedData = recalculatePercentages(orderedData, selectedGender);
      } else {
        recalculatedData = recalculatePercentages(
          orderedData.filter((item) => item.gender === selectedGender || item.gender === "general"),
          selectedGender
        );
      }
  
      if (!recalculatedData || recalculatedData.length === 0) {
        console.warn("Los datos recalculados están vacíos o no son válidos.");
        return;
      }
  
      const updatedSeries = recalculatedData.map(({ name, data, color }) => ({
        x: name,
        y: data,
        fillColor: color,
      }));
  
      if (!optionsRef.current || !optionsRef.current.chart) {
        console.error("La configuración del gráfico es inválida. No se puede proceder con la actualización.");
        return;
      }
  
      if (!optionsRef.current.chart.type) {
        console.error("El tipo de gráfico no está definido. Asegúrate de que la configuración del gráfico es correcta.");
        return;
      }
  
      optionsRef.current = {
        ...optionsRef.current,
        series: [
          {
            name: "Presence",
            data: updatedSeries,
          },
        ],
        xaxis: {
          ...optionsRef.current.xaxis,
          categories: recalculatedData.map(({ name }) => name),
        },
      };
  
      setPieData(recalculatedData.map((item) => item.data));
      pieOptionsRef.current = {
        ...pieOptionsRef.current,
        labels: recalculatedData.map(({ name }) => name),
      };
  
      setChartKey((prevKey) => prevKey + 1);
    },
    [selectedGender]
  );
  
  
  
  
  
  
  
  
  const calculateTotalTimeProgram = (job) => {
    let totalTime = 0;
  
    for (let i = 0; i < job.results.length; i++) {
      const segmentos = job.results[i];
  
      for (let j = 0; j < segmentos.length; j++) {
        const segmento = segmentos[j];
  
        if (segmento.speaker !== undefined && segmento.speaker !== null) {
          const segmentDuration = segmento.end - segmento.start;
          totalTime += segmentDuration;
        } else {
          console.log(`Segmento sin speaker, duración: ${segmento.end - segmento.start}`);
        }
      }
    }
  
    return totalTime;
  };
  
  

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toFixed(2);

    return `${hours} hores, ${minutes} minuts i ${seconds} segons`;
  };

  useEffect(() => {
    if (selectedProgram) {
      const selectedJob = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (selectedJob) {
        const totalTime = calculateTotalTimeProgram(selectedJob);

        setTotalProgramTime(totalTime);
      }
    }
  }, [selectedProgram, filteredJobs]);


const calculateUnassignedTime = (job) => {
  let totalUnassignedTime = 0;

  for (let i = 0; i < job.results.length; i++) {
    const segmentos = job.results[i];

    for (let j = 0; j < segmentos.length; j++) {
      const segmento = segmentos[j];
      if (segmento.speaker === undefined || segmento.speaker === null) {
        totalUnassignedTime += segmento.end - segmento.start;
      }
    }
  }

  return totalUnassignedTime;
};



  //empieza descarga excel

  const sanitizeSheetName = (name) => {
    const cleanedName = name.replace(/[:\\\/\?\*\[\]]/g, "");
    return cleanedName.length > 31 ? cleanedName.substring(0, 31) : cleanedName;
  };



  const handleExportMultiplePrograms = async () => {
    const workbook = XLSX.utils.book_new();
  
    const normalizedProgramName = generalSelectedProgram.trim().toLowerCase();
    const jobsForSelectedProgram = jobs.filter((job) => {
      const jobTitleNormalized = job.titol.trim().toLowerCase();
      return jobTitleNormalized.includes(normalizedProgramName);
    });
  
    if (jobsForSelectedProgram.length === 0) {
      console.warn("No se encontraron trabajos para el programa seleccionado.");
      return;
    }
  
    for (const job of jobsForSelectedProgram) {
      try {
        const transformedData = transformData(job);
        const speakerNames = Object.keys(transformedData);
  
        const totalTime = calculateTotalTimeProgram(job);

        const formattedTime = formatTime(totalTime);
  
        const programData = speakerNames.map((speaker) => {
          const { gender, totalTime: speakerTime = 0 } = transformedData[speaker] || {};
          const formattedSpeakerTime = formatTime(speakerTime);
  
          return {
            name: `S${speaker} (${gender?.charAt(0).toUpperCase() || "N/A"})`,
            gender: gender?.toLowerCase() || "unknown",
            totalTime: speakerTime,
            formattedSpeakerTime,
          };
        });
  
        const totalMaleTime = programData
          .filter((item) => item.gender === "male")
          .reduce((sum, item) => sum + item.totalTime, 0);
  
        const totalFemaleTime = programData
          .filter((item) => item.gender === "female")
          .reduce((sum, item) => sum + item.totalTime, 0);
  
        const excelGeneralData = programData.map((item) => ({
          Speaker: item.name.split(" ")[0],
          Gender: item.gender.charAt(0).toUpperCase() + item.gender.slice(1),
          PresencePercentage: (item.totalTime / totalTime) * 100, 
          TotalTime: item.formattedSpeakerTime,
        }));
  
        const excelMaleData = programData
          .filter((item) => item.gender === "male")
          .map((item) => ({
            Speaker: item.name.split(" ")[0],
            Gender: "Male",
            PresencePercentage: (item.totalTime / totalMaleTime) * 100,
            TotalTime: item.formattedSpeakerTime,
          }));
  
        const excelFemaleData = programData
          .filter((item) => item.gender === "female")
          .map((item) => ({
            Speaker: item.name.split(" ")[0],
            Gender: "Female",
            PresencePercentage: (item.totalTime / totalFemaleTime) * 100, 
            TotalTime: item.formattedSpeakerTime,
          }));
  
        const worksheet = XLSX.utils.json_to_sheet([]);
  
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [`Programa: ${job.titol}`],
            [`Temps total del programa: ${formattedTime}`],
            ["General"],
          ],
          { origin: "A1" }
        );
  
        XLSX.utils.sheet_add_json(worksheet, excelGeneralData, {
          origin: "A4",
          skipHeader: false,
        });
  
        XLSX.utils.sheet_add_aoa(worksheet, [["Homes"]], {
          origin: `A${excelGeneralData.length + 6}`,
        });
        XLSX.utils.sheet_add_json(worksheet, excelMaleData, {
          origin: `A${excelGeneralData.length + 7}`,
          skipHeader: false,
        });
  
        XLSX.utils.sheet_add_aoa(worksheet, [["Dones"]], {
          origin: `A${excelGeneralData.length + excelMaleData.length + 9}`,
        });
        XLSX.utils.sheet_add_json(worksheet, excelFemaleData, {
          origin: `A${excelGeneralData.length + excelMaleData.length + 10}`,
          skipHeader: false,
        });
  
        const sanitizedSheetName = sanitizeSheetName(job.titol);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(
          `Error fetching or transforming data for program titled ${job.titol}:`,
          error
        );
      }
    }
  
    if (workbook.SheetNames.length > 0) {
      XLSX.writeFile(workbook, "Presence_Speaker_Data_Including_Top_Speaker.xlsx");
    } else {
      console.error("Workbook is empty");
    }
  };
  
  

  const [selectedProgramIds, setSelectedProgramIds] = useState([]);

  const handleSelectPrograms = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const ids = selectedOptions.map((option) => option.value);
    setSelectedProgramIds(ids);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };


  const handleExportWithoutTopSpeaker = async () => {
    const workbook = XLSX.utils.book_new();
  
    const normalizedProgramName = generalSelectedProgram.trim().toLowerCase();
    const jobsForSelectedProgram = jobs.filter((job) => {
      const jobTitleNormalized = job.titol.trim().toLowerCase();
      return jobTitleNormalized.includes(normalizedProgramName);
    });
  
    if (jobsForSelectedProgram.length === 0) {
      console.warn("No se encontraron trabajos para el programa seleccionado.");
      return;
    }
  
    for (const job of jobsForSelectedProgram) {
      try {
        const transformedData = transformData(job);
        const speakerNames = Object.keys(transformedData);
        const totalTime = calculateTotalTimeProgram(job);
        const formattedTime = formatTime(totalTime);
  
        const programData = speakerNames.map((speaker) => {
          const { gender, totalTime: speakerTime = 0 } = transformedData[speaker] || {};
          return {
            name: `S${speaker} (${gender.charAt(0).toUpperCase()})`,
            gender: gender.toLowerCase(),
            totalTime: speakerTime,
          };
        });
  
        const maxPresenceSpeaker = Math.max(...programData.map((s) => s.totalTime));
        const topSpeaker = programData.find(
          (speaker) => speaker.totalTime === maxPresenceSpeaker
        );
  
        const filteredData = programData.filter(
          (speaker) => speaker.totalTime !== maxPresenceSpeaker
        );
  
        if (filteredData.length === 0) {
          console.warn(
            "No hay datos para exportar después de excluir al speaker principal."
          );
          continue;
        }
  
        const recalculatedTotalTime = filteredData.reduce(
          (sum, speaker) => sum + speaker.totalTime,
          0
        );
  
        const recalculatedGeneral = filteredData.map((speaker) => ({
          Speaker: speaker.name.split(" ")[0],
          Gender: speaker.gender.charAt(0).toUpperCase() + speaker.gender.slice(1),
          PresencePercentage: recalculatedTotalTime > 0
            ? (speaker.totalTime / recalculatedTotalTime) * 100
            : 0,
          TotalTime: formatTime(speaker.totalTime),
        }));
  
  
        const maleSpeakers = filteredData.filter(
          (speaker) => speaker.gender === "male"
        );
        const femaleSpeakers = filteredData.filter(
          (speaker) => speaker.gender === "female"
        );
  
        const totalMaleTime = maleSpeakers.reduce((sum, speaker) => sum + speaker.totalTime, 0);
        const totalFemaleTime = femaleSpeakers.reduce((sum, speaker) => sum + speaker.totalTime, 0);
  
        const recalculatedMale = maleSpeakers.map((speaker) => ({
          Speaker: speaker.name.split(" ")[0],
          Gender: "Male",
          PresencePercentage: totalMaleTime > 0 ? (speaker.totalTime / totalMaleTime) * 100 : 0,
          TotalTime: formatTime(speaker.totalTime),
        }));
  
        const recalculatedFemale = femaleSpeakers.map((speaker) => ({
          Speaker: speaker.name.split(" ")[0],
          Gender: "Female",
          PresencePercentage: totalFemaleTime > 0 ? (speaker.totalTime / totalFemaleTime) * 100 : 0,
          TotalTime: formatTime(speaker.totalTime),
        }));
  
        const worksheet = XLSX.utils.json_to_sheet([]);
  
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [`Programa: ${job.titol}`],
            [`Data: ${job.date}`],
            [`Temps total del programa: ${formattedTime}`],
            [`Top Speaker exclòs: ${topSpeaker.name}`],
            ["General"],
          ],
          { origin: "A1" }
        );
  
        XLSX.utils.sheet_add_json(worksheet, recalculatedGeneral, {
          origin: "A6",
          skipHeader: false,
        });
  
        XLSX.utils.sheet_add_aoa(worksheet, [["Homes"]], {
          origin: `A${recalculatedGeneral.length + 8}`,
        });
        XLSX.utils.sheet_add_json(worksheet, recalculatedMale, {
          origin: `A${recalculatedGeneral.length + 9}`,
          skipHeader: false,
        });
  
        XLSX.utils.sheet_add_aoa(worksheet, [["Dones"]], {
          origin: `A${recalculatedGeneral.length + recalculatedMale.length + 11}`,
        });
        XLSX.utils.sheet_add_json(worksheet, recalculatedFemale, {
          origin: `A${recalculatedGeneral.length + recalculatedMale.length + 12}`,
          skipHeader: false,
        });
  
        const sanitizedSheetName = sanitizeSheetName(job.titol);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(
          `Error al obtener o transformar datos para el ID del programa ${job.id}:`,
          error
        );
      }
    }
  
    if (workbook.SheetNames.length > 0) {
      XLSX.writeFile(
        workbook,
        "Presence_Speaker_Data_Excluding_Top_Speaker.xlsx"
      );
    } else {
      console.error(
        "El archivo de Excel está vacío después de procesar todos los trabajos."
      );
    }
  };
  
  
  
  

  
  return (
    <div id="chart" className="w-full max-w-screen-lg mx-auto text-black">
      <div className="mb-4">
        <h1 className="text-xs text-black">Presència Speaker per dia</h1>
        <p className="italic text-xs text-black">
          Pots interactuar amb el gràfic fent click a una barra per eliminar-la
          i obtindre nous resultats.
        </p>
      </div>
      {jobs.length > 0 ? (
        <div>
          <select
            className="text-black bg-transparent border-2 m-2"
            onChange={handleChangeJob}
            value={selectedJob || " "}
          >
            {jobs.map((job) => (
              <option key={job.id} value={job.id}>
                {job.titol}
              </option>
            ))}
          </select>
          <select
            className="text-black bg-transparent border-2"
            onChange={handleChangeGender}
            value={selectedGender}
          >
            <option value="general">General</option>
            <option value="male">Homes</option>
            <option value="female">Dones</option>
          </select>
        </div>
      ) : (
        "Loading jobs..."
      )}
      <div className="overflow-x-auto">
        <div style={{ width: `${chartDataRef.current.length * 60}px` }}>
          <ReactApexChart
            key={chartKey}
            options={optionsRef.current}
            series={optionsRef.current.series}
            type="bar"
            height={optionsRef.current.chart.height}
          />
        </div>
      </div>

      {removedBarsRef.current.length > 0 && (
        <div className="mt-4 p-4 bg-yellow-200 border border-yellow-400 text-yellow-800">
          <p className="text-black">Les barres que has eliminat son:</p>
          <ul>
            {removedBarsRef.current.map((bar, index) => (
              <li
                key={index}
                onClick={() => handleRestoreBar(bar.name)}
                className="cursor-pointer underline"
              >
                <strong className="text-black">{`${bar.name} (${
                  bar.gender === "male" ? "Home" : "Dona"
                })`}</strong>
              </li>
            ))}
          </ul>
          <p className="text-xs italic text-black">
            Pots restaurar les barres fent click de nou
          </p>
        </div>
      )}

      <div className="flex flex-row gap-4">
        <div className="flex-grow">
          <SpeakersList
            selectedJobId={selectedJob}
            speakersData={chartDataRef.current}
          />
        </div>
        <div className="flex-grow">
          <ReactApexChart
            options={pieOptionsRef.current}
            series={pieData}
            type="pie"
            width={400}
          />
        </div>
      </div>
      <div className="m-4 flex flex-row gap-4 items-start">
        <button
          onClick={() => handleExportMultiplePrograms(selectedProgramIds)}
          className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Excel inclou speakers
        </button>
        <button
          onClick={() => handleExportWithoutTopSpeaker(selectedProgramIds)}
          className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Excel exclou top speaker
        </button>

        {showExcel && (
          <div className="flex flex-col gap-4 mt-4">
            {/* <input
              type="text"
              placeholder="Filtrar programes"
              value={filterText}
              onChange={handleFilterChange}
              className="border p-2 rounded bg-transparent text-black w-80 justify-start"
            />
            <select
              multiple
              size={10}
              onChange={handleSelectPrograms}
              className="text-black bg-transparent border-2 w-full"
            >
              {filteredJobs.map((job) => (
                <option key={job.id} value={job.id}>
                  {job.titol}
                </option>
              ))}
            </select> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SpeakersChart;
