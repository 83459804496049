import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { parse, getISOWeek, getMonth, format, isValid } from "date-fns";
import { useJobs } from "../../hooks/useJobs/useJobs";
import FilterSelect from "../FilterSelect/FilterSelect";
import MultiSelect from "../MultiSelect/MultiSelect";

const StackedChart = ({
  generalSelectedProgram,
  selectedProgram,
  setSelectedProgram,
}) => {
  const { getJobs } = useJobs();

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("day");
  const [selectedDates, setSelectedDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uniqueWeeksAndMonths, setUniqueWeeksAndMonths] = useState([]);
  const [uniqueDays, setUniqueDays] = useState([]);
  //estados para excel
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [filterText, setFilterText] = useState("");
  //estado calcular el tiempo total del programa
  const [totalProgramTime, setTotalProgramTime] = useState(0);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        setJobs(response);
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchJobs();
  }, [getJobs]);

  useEffect(() => {
    if (generalSelectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol.includes(generalSelectedProgram)
      );
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs([]);
    }
  }, [generalSelectedProgram, jobs]);

  useEffect(() => {
    const getUniqueWeeksAndMonths = () => {
      let uniqueValues = new Set();

      filteredJobs.forEach((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        if (
          selectedFilter === "SETMANA" ||
          selectedFilter === "SETMANA - DIES"
        ) {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          uniqueValues.add(weekLabel);
        } else if (
          selectedFilter === "MES" ||
          selectedFilter === "MES - DIES"
        ) {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          uniqueValues.add(monthLabel);
        } else if (selectedFilter === "day") {
          const dayLabel = format(date, "dd/MM/yyyy");
          uniqueValues.add(dayLabel);
        }
      });

      return Array.from(uniqueValues).map((value) => ({
        value: value,
        label: value,
      }));
    };

    setUniqueWeeksAndMonths(getUniqueWeeksAndMonths());
    setUniqueDays(
      getUniqueWeeksAndMonths().filter((value) =>
        value.label.match(/^\d{2}\/\d{2}\/\d{4}$/)
      )
    );
  }, [selectedFilter, filteredJobs]);

  /* useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedFilter) {
        setChartSeries([]);
        setCategories([]);
        return;
      }

      let maleCounts = {};
      let femaleCounts = {};
      let totalCounts = {};

      const jobsInSelectedDates = filteredJobs.filter((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return false;

        const date = parse(dateStr, "dd/MM/yyyy", new Date());
        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Setmana ${weekNumber}`
          );
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === weekLabel
          );
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Mes ${monthNumber}`
          );
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === monthLabel
          );
        } else if (selectedFilter === "day") {
          return selectedDates.some(
            (selectedDate) => selectedDate.value === format(date, "dd/MM/yyyy")
          );
        }
        return false;
      });

      jobsInSelectedDates.forEach((job) => {
        const dateStr = job.date;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;

          if (!maleCounts[weekLabel]) maleCounts[weekLabel] = 0;
          if (!femaleCounts[weekLabel]) femaleCounts[weekLabel] = 0;
          if (!totalCounts[weekLabel]) totalCounts[weekLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[weekLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[weekLabel]++;
            }
            totalCounts[weekLabel]++;
          });
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleCounts[weekLabel]) maleCounts[weekLabel] = {};
          if (!femaleCounts[weekLabel]) femaleCounts[weekLabel] = {};
          if (!totalCounts[weekLabel]) totalCounts[weekLabel] = {};

          if (!maleCounts[weekLabel][dayLabel])
            maleCounts[weekLabel][dayLabel] = 0;
          if (!femaleCounts[weekLabel][dayLabel])
            femaleCounts[weekLabel][dayLabel] = 0;
          if (!totalCounts[weekLabel][dayLabel])
            totalCounts[weekLabel][dayLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[weekLabel][dayLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[weekLabel][dayLabel]++;
            }
            totalCounts[weekLabel][dayLabel]++;
          });
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;

          if (!maleCounts[monthLabel]) maleCounts[monthLabel] = 0;
          if (!femaleCounts[monthLabel]) femaleCounts[monthLabel] = 0;
          if (!totalCounts[monthLabel]) totalCounts[monthLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[monthLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[monthLabel]++;
            }
            totalCounts[monthLabel]++;
          });
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleCounts[monthLabel]) maleCounts[monthLabel] = {};
          if (!femaleCounts[monthLabel]) femaleCounts[monthLabel] = {};
          if (!totalCounts[monthLabel]) totalCounts[monthLabel] = {};

          if (!maleCounts[monthLabel][dayLabel])
            maleCounts[monthLabel][dayLabel] = 0;
          if (!femaleCounts[monthLabel][dayLabel])
            femaleCounts[monthLabel][dayLabel] = 0;
          if (!totalCounts[monthLabel][dayLabel])
            totalCounts[monthLabel][dayLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[monthLabel][dayLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[monthLabel][dayLabel]++;
            }
            totalCounts[monthLabel][dayLabel]++;
          });
        } else if (selectedFilter === "day") {
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleCounts[dayLabel]) maleCounts[dayLabel] = 0;
          if (!femaleCounts[dayLabel]) femaleCounts[dayLabel] = 0;
          if (!totalCounts[dayLabel]) totalCounts[dayLabel] = 0;

          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCounts[dayLabel]++;
            } else if (result.gender === "FEMALE") {
              femaleCounts[dayLabel]++;
            }
            totalCounts[dayLabel]++;
          });
        }
      });

      let newCategories = [];
      let maleData = [];
      let femaleData = [];

      if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
        newCategories = Object.keys(maleCounts);
        maleData = newCategories.map((label) =>
          ((maleCounts[label] / totalCounts[label]) * 100).toFixed(2)
        );
        femaleData = newCategories.map((label) =>
          ((femaleCounts[label] / totalCounts[label]) * 100).toFixed(2)
        );
      } else if (
        selectedFilter === "SETMANA - DIES" ||
        selectedFilter === "MES - DIES"
      ) {
        newCategories =
          selectedFilter === "SETMANA - DIES"
            ? Object.keys(maleCounts).flatMap((weekLabel) =>
                Object.keys(maleCounts[weekLabel])
              )
            : Object.keys(maleCounts).flatMap((monthLabel) =>
                Object.keys(maleCounts[monthLabel])
              );

        maleData = newCategories.map((dayLabel) => {
          const parentLabel =
            selectedFilter === "SETMANA - DIES"
              ? `Setmana ${getISOWeek(
                  parse(dayLabel, "dd/MM/yyyy", new Date())
                )}`
              : `Mes ${
                  getMonth(parse(dayLabel, "dd/MM/yyyy", new Date())) + 1
                }`;
          return (
            (maleCounts[parentLabel][dayLabel] /
              totalCounts[parentLabel][dayLabel]) *
            100
          ).toFixed(2);
        });

        femaleData = newCategories.map((dayLabel) => {
          const parentLabel =
            selectedFilter === "SETMANA - DIES"
              ? `Setmana ${getISOWeek(
                  parse(dayLabel, "dd/MM/yyyy", new Date())
                )}`
              : `Mes ${
                  getMonth(parse(dayLabel, "dd/MM/yyyy", new Date())) + 1
                }`;
          return (
            (femaleCounts[parentLabel][dayLabel] /
              totalCounts[parentLabel][dayLabel]) *
            100
          ).toFixed(2);
        });
      } else if (selectedFilter === "day") {
        newCategories = Object.keys(maleCounts);
        maleData = newCategories.map((dayLabel) =>
          ((maleCounts[dayLabel] / totalCounts[dayLabel]) * 100).toFixed(2)
        );
        femaleData = newCategories.map((dayLabel) =>
          ((femaleCounts[dayLabel] / totalCounts[dayLabel]) * 100).toFixed(2)
        );
      }

      setCategories(newCategories);
      setChartSeries([
        {
          name: "HOMES",
          data: maleData,
        },
        {
          name: "DONES",
          data: femaleData,
        },
      ]);
    };

    calculateGenderStats();
  }, [
    selectedFilter,
    selectedDates,
    filteredJobs,
    selectedProgram,
    generalSelectedProgram,
  ]); */

  useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedFilter) {
        setChartSeries([]);
        setCategories([]);
        return;
      }

      let maleTimes = {};
      let femaleTimes = {};
      let totalTimes = {};

      const jobsInSelectedDates = filteredJobs.filter((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return false;

        const date = parse(dateStr, "dd/MM/yyyy", new Date());
        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Setmana ${weekNumber}`
          );
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === weekLabel
          );
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Mes ${monthNumber}`
          );
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === monthLabel
          );
        } else if (selectedFilter === "day") {
          return selectedDates.some(
            (selectedDate) => selectedDate.value === format(date, "dd/MM/yyyy")
          );
        }
        return false;
      });

      jobsInSelectedDates.forEach((job) => {
        const dateStr = job.date;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        let periodLabel;

        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          periodLabel = `Setmana ${weekNumber}`;

          if (!maleTimes[periodLabel]) maleTimes[periodLabel] = 0;
          if (!femaleTimes[periodLabel]) femaleTimes[periodLabel] = 0;
          if (!totalTimes[periodLabel]) totalTimes[periodLabel] = 0;

          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTimes[periodLabel] += duration;
            } else if (result.gender === "FEMALE") {
              femaleTimes[periodLabel] += duration;
            }
            totalTimes[periodLabel] += duration;
          });
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleTimes[weekLabel]) maleTimes[weekLabel] = {};
          if (!femaleTimes[weekLabel]) femaleTimes[weekLabel] = {};
          if (!totalTimes[weekLabel]) totalTimes[weekLabel] = {};

          if (!maleTimes[weekLabel][dayLabel])
            maleTimes[weekLabel][dayLabel] = 0;
          if (!femaleTimes[weekLabel][dayLabel])
            femaleTimes[weekLabel][dayLabel] = 0;
          if (!totalTimes[weekLabel][dayLabel])
            totalTimes[weekLabel][dayLabel] = 0;

          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTimes[weekLabel][dayLabel] += duration;
            } else if (result.gender === "FEMALE") {
              femaleTimes[weekLabel][dayLabel] += duration;
            }
            totalTimes[weekLabel][dayLabel] += duration;
          });
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          periodLabel = `Mes ${monthNumber}`;

          if (!maleTimes[periodLabel]) maleTimes[periodLabel] = 0;
          if (!femaleTimes[periodLabel]) femaleTimes[periodLabel] = 0;
          if (!totalTimes[periodLabel]) totalTimes[periodLabel] = 0;

          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTimes[periodLabel] += duration;
            } else if (result.gender === "FEMALE") {
              femaleTimes[periodLabel] += duration;
            }
            totalTimes[periodLabel] += duration;
          });
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          const dayLabel = format(date, "dd/MM/yyyy");

          if (!maleTimes[monthLabel]) maleTimes[monthLabel] = {};
          if (!femaleTimes[monthLabel]) femaleTimes[monthLabel] = {};
          if (!totalTimes[monthLabel]) totalTimes[monthLabel] = {};

          if (!maleTimes[monthLabel][dayLabel])
            maleTimes[monthLabel][dayLabel] = 0;
          if (!femaleTimes[monthLabel][dayLabel])
            femaleTimes[monthLabel][dayLabel] = 0;
          if (!totalTimes[monthLabel][dayLabel])
            totalTimes[monthLabel][dayLabel] = 0;

          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTimes[monthLabel][dayLabel] += duration;
            } else if (result.gender === "FEMALE") {
              femaleTimes[monthLabel][dayLabel] += duration;
            }
            totalTimes[monthLabel][dayLabel] += duration;
          });
        } else if (selectedFilter === "day") {
          periodLabel = format(date, "dd/MM/yyyy");

          if (!maleTimes[periodLabel]) maleTimes[periodLabel] = 0;
          if (!femaleTimes[periodLabel]) femaleTimes[periodLabel] = 0;
          if (!totalTimes[periodLabel]) totalTimes[periodLabel] = 0;

          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTimes[periodLabel] += duration;
            } else if (result.gender === "FEMALE") {
              femaleTimes[periodLabel] += duration;
            }
            totalTimes[periodLabel] += duration;
          });
        }
      });

      let newCategories = [];
      let maleData = [];
      let femaleData = [];

      if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
        newCategories = Object.keys(maleTimes);
        maleData = newCategories.map((label) =>
          ((maleTimes[label] / totalTimes[label]) * 100).toFixed(2)
        );
        femaleData = newCategories.map((label) =>
          ((femaleTimes[label] / totalTimes[label]) * 100).toFixed(2)
        );
      } else if (
        selectedFilter === "SETMANA - DIES" ||
        selectedFilter === "MES - DIES"
      ) {
        newCategories =
          selectedFilter === "SETMANA - DIES"
            ? Object.keys(maleTimes).flatMap((weekLabel) =>
                Object.keys(maleTimes[weekLabel])
              )
            : Object.keys(maleTimes).flatMap((monthLabel) =>
                Object.keys(maleTimes[monthLabel])
              );

        maleData = newCategories.map((dayLabel) => {
          const parentLabel =
            selectedFilter === "SETMANA - DIES"
              ? `Setmana ${getISOWeek(
                  parse(dayLabel, "dd/MM/yyyy", new Date())
                )}`
              : `Mes ${
                  getMonth(parse(dayLabel, "dd/MM/yyyy", new Date())) + 1
                }`;
          return (
            (maleTimes[parentLabel][dayLabel] /
              totalTimes[parentLabel][dayLabel]) *
            100
          ).toFixed(2);
        });

        femaleData = newCategories.map((dayLabel) => {
          const parentLabel =
            selectedFilter === "SETMANA - DIES"
              ? `Setmana ${getISOWeek(
                  parse(dayLabel, "dd/MM/yyyy", new Date())
                )}`
              : `Mes ${
                  getMonth(parse(dayLabel, "dd/MM/yyyy", new Date())) + 1
                }`;
          return (
            (femaleTimes[parentLabel][dayLabel] /
              totalTimes[parentLabel][dayLabel]) *
            100
          ).toFixed(2);
        });
      } else if (selectedFilter === "day") {
        newCategories = Object.keys(maleTimes);
        maleData = newCategories.map((dayLabel) =>
          ((maleTimes[dayLabel] / totalTimes[dayLabel]) * 100).toFixed(2)
        );
        femaleData = newCategories.map((dayLabel) =>
          ((femaleTimes[dayLabel] / totalTimes[dayLabel]) * 100).toFixed(2)
        );
      }

      setCategories(newCategories);
      setChartSeries([
        {
          name: "HOMES",
          data: maleData,
        },
        {
          name: "DONES",
          data: femaleData,
        },
      ]);
    };

    calculateGenderStats();
  }, [
    selectedFilter,
    selectedDates,
    filteredJobs,
    selectedProgram,
    generalSelectedProgram,
  ]);


  useEffect(() => {
    if (selectedFilter === "day" && selectedProgram) {
      const programDay = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (programDay) {
        const programDate = format(
          parse(programDay.date, "dd/MM/yyyy", new Date()),
          "dd/MM/yyyy"
        );
        setSelectedDates([{ value: programDate, label: programDate }]);
      }
    }
  }, [selectedFilter, selectedProgram, filteredJobs]);

  const sortedFilteredJobs = filteredJobs.sort((a, b) => {
    const dateA = parse(a.date, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date, "dd/MM/yyyy", new Date());
    return dateA - dateB;
  });

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "day" && selectedProgram) {
      const programDay = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (programDay) {
        const programDate = format(
          parse(programDay.date, "dd/MM/yyyy", new Date()),
          "dd/MM/yyyy"
        );
        setSelectedDates([{ value: programDate, label: programDate }]);
      } else {
        setSelectedDates([]);
      }
    } else {
      setSelectedDates([]);
    }
  };

  useEffect(() => {
    if (selectedProgram) {
      const programDay = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (programDay) {
        const programDate = format(
          parse(programDay.date, "dd/MM/yyyy", new Date()),
          "dd/MM/yyyy"
        );
        setSelectedDates([{ value: programDate, label: programDate }]);
      }
    }
  }, [selectedProgram, filteredJobs]);

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      height: 500,
      width: "1000",
    },
    xaxis: {
      categories: categories,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
  };

  const calculateTotalTimeProgram = (job) => {
    let totalTime = 0;

    for (let i = 0; i < job.results.length; i++) {
      const segmentos = job.results[i];

      for (let j = 0; j < segmentos.length; j++) {
        const segmento = segmentos[j];
        totalTime += segmento.end - segmento.start;
      }
    }

    return totalTime;
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toFixed(2);

    return `${hours} hores, ${minutes} minuts i ${seconds} segons`;
  };

  useEffect(() => {
    if (selectedProgram) {
      const selectedJob = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (selectedJob) {
        const totalTime = calculateTotalTimeProgram(selectedJob);

        setTotalProgramTime(totalTime);
      }
    }
  }, [selectedProgram, filteredJobs]);

  //empezamos con el excel

  const sanitizeSheetName = (name) => {
    return name.replace(/[:\\\/\?\*\[\]]/g, "").substring(0, 31);
  };

  const filteredJobsForExport = filteredJobs.filter((job) => {
    const date = parse(job.date, "dd/MM/yyyy", new Date());

    if (!isValid(date)) {
      console.warn(`Fecha inválida encontrada en el trabajo con ID: ${job.id}`);
      return false;
    }

    if (selectedFilter === "SETMANA") {
      const weekNumber = getISOWeek(date);
      return selectedDates.some(
        (date) => date.value === `Setmana ${weekNumber}`
      );
    } else if (selectedFilter === "MES") {
      const monthNumber = getMonth(date) + 1;
      return selectedDates.some((date) => date.value === `Mes ${monthNumber}`);
    } else if (selectedFilter === "day") {
      const formattedDate = format(date, "dd/MM/yyyy");
      return selectedDates.some((date) => date.value === formattedDate);
    }
    return false;
  });


  /* const handleExportByDay = async () => {
    const workbook = XLSX.utils.book_new();
  
    for (const job of filteredJobsForExport) {
      let maleCount = 0;
      let femaleCount = 0;
  
      job.results[0].forEach((result) => {
        if (result.gender === "MALE") {
          maleCount++;
        } else if (result.gender === "FEMALE") {
          femaleCount++;
        }
      });
  
      const totalJobCount = maleCount + femaleCount;
      const malePercentage = totalJobCount > 0 ? (maleCount / totalJobCount) * 100 : 0;
      const femalePercentage = totalJobCount > 0 ? (femaleCount / totalJobCount) * 100 : 0;
  
      const totalTime = calculateTotalTimeProgram(job);
      const formattedTime = formatTime(totalTime);
  
      const programData = [
        {
          Category: "Dones",
          Percentage: femalePercentage.toFixed(2),
        },
        {
          Category: "Homes",
          Percentage: malePercentage.toFixed(2),
        },
      ];
  
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, [
        [`Programa: ${job.titol}`],
        [`Data: ${job.date}`],
        [`Temps: ${formattedTime}`],
        ["Category", "Percentage"],
      ]);
      XLSX.utils.sheet_add_json(worksheet, programData, {
        origin: "A5",
        skipHeader: true,
      });
  
      XLSX.utils.book_append_sheet(workbook, worksheet, sanitizeSheetName(`${job.titol}`));
    }
  
    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }
  
    XLSX.writeFile(workbook, "Stacked_Gender_Distribution_Including_TopSpeaker_ByDay.xlsx");
  };

 */

  const handleExportByDay = async () => {
    const workbook = XLSX.utils.book_new();
  
    for (const job of filteredJobsForExport) {
      let maleTime = 0;
      let femaleTime = 0;
  
      job.results[0].forEach((result) => {
        const duration = result.end - result.start;
        if (result.gender === "MALE") {
          maleTime += duration;
        } else if (result.gender === "FEMALE") {
          femaleTime += duration;
        }
      });
  
      const totalJobTime = maleTime + femaleTime;
      const malePercentage = totalJobTime > 0 ? (maleTime / totalJobTime) * 100 : 0;
      const femalePercentage = totalJobTime > 0 ? (femaleTime / totalJobTime) * 100 : 0;
  
      const totalTime = calculateTotalTimeProgram(job);
      const formattedTime = formatTime(totalTime);
  
      const programData = [
        {
          Category: "Dones",
          Percentage: femalePercentage.toFixed(2),
        },
        {
          Category: "Homes",
          Percentage: malePercentage.toFixed(2),
        },
      ];
  
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, [
        [`Programa: ${job.titol}`],
        [`Data: ${job.date}`],
        [`Temps: ${formattedTime}`],
        ["Category", "Percentage"],
      ]);
      XLSX.utils.sheet_add_json(worksheet, programData, {
        origin: "A5",
        skipHeader: true,
      });
  
      XLSX.utils.book_append_sheet(workbook, worksheet, sanitizeSheetName(`${job.titol}`));
    }
  
    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }
  
    XLSX.writeFile(workbook, "Stacked_Gender_Distribution_Including_TopSpeaker_ByDay.xlsx");
  };
  

  const filterByWeekAndDays = (jobs, selectedDates) => {
    return jobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      const weekNumber = getISOWeek(jobDate);
      const dayLabel = format(jobDate, "dd/MM/yyyy");

      const selectedWeek = `Setmana ${weekNumber}`;

      const isWeekSelected = selectedDates.some(
        (date) => date.value === selectedWeek
      );

      if (isWeekSelected) {
        return true;
      }

      const isDaySelected = selectedDates.some(
        (date) => date.value === dayLabel
      );

      return isDaySelected;
    });
  };


  const filterByMonthAndDays = (jobs, selectedDates) => {
    const selectedMonths = selectedDates.map(date => parseInt(date.value.split(" ")[1]));

    return jobs.filter((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const monthNumber = getMonth(jobDate) + 1;
        const dayLabel = format(jobDate, "dd/MM/yyyy");

        return selectedMonths.includes(monthNumber);
    });
};


 

 /*  const handleExportByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();
  
    let filteredPrograms = [];
  
    if (selectedFilter === "SETMANA - DIES") {
      filteredPrograms = filterByWeekAndDays(filteredJobs, selectedDates);
    } else if (selectedFilter === "MES - DIES") {
      filteredPrograms = filterByMonthAndDays(filteredJobs, selectedDates);
    } else {
      filteredPrograms = filteredJobs.filter((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const weekNumber = getISOWeek(jobDate);
        const monthNumber = getMonth(jobDate) + 1;
  
        return selectedDates.some((date) => {
          if (selectedFilter === "SETMANA") {
            const weekFromSelection = parseInt(date.value.split(" ")[1]);
            return weekNumber === weekFromSelection;
          } else if (selectedFilter === "MES") {
            const monthFromSelection = parseInt(date.value.split(" ")[1]);
            return monthNumber === monthFromSelection;
          }
          return false;
        });
      });
    }
  
    if (filteredPrograms.length === 0) {
      console.error("No data to export");
      return;
    }
  
    if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
      let summary = {};
  
      filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel = selectedFilter === "SETMANA" ? `Setmana ${getISOWeek(jobDate)}` : `Mes ${getMonth(jobDate) + 1}`;
  
        if (!summary[timeLabel]) {
          summary[timeLabel] = {
            maleCount: 0,
            femaleCount: 0,
            totalTime: 0,
          };
        }
  
        job.results[0].forEach((result) => {
          if (result.gender === "MALE") {
            summary[timeLabel].maleCount++;
          } else if (result.gender === "FEMALE") {
            summary[timeLabel].femaleCount++;
          }
        });
  
        summary[timeLabel].totalTime += calculateTotalTimeProgram(job);
      });
  
      Object.keys(summary).forEach((timeLabel) => {
        const { maleCount, femaleCount, totalTime } = summary[timeLabel];
        const totalCount = maleCount + femaleCount;
        const malePercentage = totalCount > 0 ? (maleCount / totalCount) * 100 : 0;
        const femalePercentage = totalCount > 0 ? (femaleCount / totalCount) * 100 : 0;
  
        const programData = [
          { Category: "Dones", Percentage: femalePercentage.toFixed(2) },
          { Category: "Homes", Percentage: malePercentage.toFixed(2) },
        ];
  
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [`Resum de ${timeLabel}`],
            [`Total de temps: ${formatTime(totalTime)}`],
            ["Category", "Percentage"],
          ],
          { origin: "A1" }
        );
  
        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A4",
          skipHeader: true,
        });
  
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizeSheetName(timeLabel));
      });
    } else if (selectedFilter === "SETMANA - DIES" || selectedFilter === "MES - DIES") {
      let summaryByDays = {};
  
      filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel = selectedFilter === "SETMANA - DIES" ? `Setmana ${getISOWeek(jobDate)}` : `Mes ${getMonth(jobDate) + 1}`;
        const dayLabel = format(jobDate, "dd/MM/yyyy");
  
        if (!summaryByDays[timeLabel]) {
          summaryByDays[timeLabel] = {};
        }
  
        if (!summaryByDays[timeLabel][dayLabel]) {
          summaryByDays[timeLabel][dayLabel] = {
            maleCount: 0,
            femaleCount: 0,
            totalTime: 0,
          };
        }
  
        job.results[0].forEach((result) => {
          if (result.gender === "MALE") {
            summaryByDays[timeLabel][dayLabel].maleCount++;
          } else if (result.gender === "FEMALE") {
            summaryByDays[timeLabel][dayLabel].femaleCount++;
          }
        });
  
        summaryByDays[timeLabel][dayLabel].totalTime += calculateTotalTimeProgram(job);
      });
  
      Object.keys(summaryByDays).forEach((timeLabel) => {
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [[`Resum de ${timeLabel}`]]);
  
        let rowStart = 2;
        const sortedDays = Object.keys(summaryByDays[timeLabel]).sort(
          (a, b) => new Date(a) - new Date(b)
        );
  
        sortedDays.forEach((dayLabel) => {
          const { maleCount, femaleCount, totalTime } = summaryByDays[timeLabel][dayLabel];
          const totalCount = maleCount + femaleCount;
          const malePercentage = totalCount > 0 ? (maleCount / totalCount) * 100 : 0;
          const femalePercentage = totalCount > 0 ? (femaleCount / totalCount) * 100 : 0;
  
          const dayData = [
            { Category: "Dones", Percentage: femalePercentage.toFixed(2) },
            { Category: "Homes", Percentage: malePercentage.toFixed(2) },
          ];
  
          XLSX.utils.sheet_add_aoa(
            worksheet,
            [
              [`Data: ${dayLabel}`, `Total de temps: ${formatTime(totalTime)}`],
              ["Category", "Percentage"],
            ],
            { origin: `A${rowStart}` }
          );
  
          XLSX.utils.sheet_add_json(worksheet, dayData, {
            origin: `A${rowStart + 3}`,
            skipHeader: true,
          });
  
          rowStart += 6;
        });
  
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizeSheetName(timeLabel));
      });
    }
  
    if (workbook.SheetNames.length === 0) {
      console.error("No sheets were created");
      return;
    }
  
    XLSX.writeFile(workbook, `Stacked_Gender_Distribution_Including_TopSpeaker_${selectedFilter}.xlsx`);
  }; */

  const handleExportByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();
  
    let filteredPrograms = [];
  
    if (selectedFilter === "SETMANA - DIES") {
      filteredPrograms = filterByWeekAndDays(filteredJobs, selectedDates);
    } else if (selectedFilter === "MES - DIES") {
      filteredPrograms = filterByMonthAndDays(filteredJobs, selectedDates);
    } else {
      filteredPrograms = filteredJobs.filter((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const weekNumber = getISOWeek(jobDate);
        const monthNumber = getMonth(jobDate) + 1;
  
        return selectedDates.some((date) => {
          if (selectedFilter === "SETMANA") {
            const weekFromSelection = parseInt(date.value.split(" ")[1]);
            return weekNumber === weekFromSelection;
          } else if (selectedFilter === "MES") {
            const monthFromSelection = parseInt(date.value.split(" ")[1]);
            return monthNumber === monthFromSelection;
          }
          return false;
        });
      });
    }
  
    if (filteredPrograms.length === 0) {
      console.error("No data to export");
      return;
    }
  
    if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
      let summary = {};
  
      filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel =
          selectedFilter === "SETMANA"
            ? `Setmana ${getISOWeek(jobDate)}`
            : `Mes ${getMonth(jobDate) + 1}`;
  
        if (!summary[timeLabel]) {
          summary[timeLabel] = {
            maleTime: 0,
            femaleTime: 0,
            totalTime: 0,
          };
        }
  
        job.results[0].forEach((result) => {
          const duration = result.end - result.start;
          if (result.gender === "MALE") {
            summary[timeLabel].maleTime += duration;
          } else if (result.gender === "FEMALE") {
            summary[timeLabel].femaleTime += duration;
          }
          summary[timeLabel].totalTime += duration;
        });
      });
  
      Object.keys(summary).forEach((timeLabel) => {
        const { maleTime, femaleTime, totalTime } = summary[timeLabel];
        const malePercentage =
          totalTime > 0 ? (maleTime / totalTime) * 100 : 0;
        const femalePercentage =
          totalTime > 0 ? (femaleTime / totalTime) * 100 : 0;
  
        const programData = [
          { Category: "Dones", Percentage: femalePercentage.toFixed(2) },
          { Category: "Homes", Percentage: malePercentage.toFixed(2) },
        ];
  
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [`Resum de ${timeLabel}`],
            [`Total de temps: ${formatTime(totalTime)}`],
            ["Category", "Percentage"],
          ],
          { origin: "A1" }
        );
  
        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A4",
          skipHeader: true,
        });
  
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizeSheetName(timeLabel)
        );
      });
    } else if (
      selectedFilter === "SETMANA - DIES" ||
      selectedFilter === "MES - DIES"
    ) {
      let summaryByDays = {};
  
      filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel =
          selectedFilter === "SETMANA - DIES"
            ? `Setmana ${getISOWeek(jobDate)}`
            : `Mes ${getMonth(jobDate) + 1}`;
        const dayLabel = format(jobDate, "dd/MM/yyyy");
  
        if (!summaryByDays[timeLabel]) {
          summaryByDays[timeLabel] = {};
        }
  
        if (!summaryByDays[timeLabel][dayLabel]) {
          summaryByDays[timeLabel][dayLabel] = {
            maleTime: 0,
            femaleTime: 0,
            totalTime: 0,
          };
        }
  
        job.results[0].forEach((result) => {
          const duration = result.end - result.start;
          if (result.gender === "MALE") {
            summaryByDays[timeLabel][dayLabel].maleTime += duration;
          } else if (result.gender === "FEMALE") {
            summaryByDays[timeLabel][dayLabel].femaleTime += duration;
          }
          summaryByDays[timeLabel][dayLabel].totalTime += duration;
        });
      });
  
      Object.keys(summaryByDays).forEach((timeLabel) => {
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [[`Resum de ${timeLabel}`]]);
  
        let rowStart = 2;
        const sortedDays = Object.keys(summaryByDays[timeLabel]).sort(
          (a, b) => new Date(a) - new Date(b)
        );
  
        sortedDays.forEach((dayLabel) => {
          const { maleTime, femaleTime, totalTime } =
            summaryByDays[timeLabel][dayLabel];
          const malePercentage =
            totalTime > 0 ? (maleTime / totalTime) * 100 : 0;
          const femalePercentage =
            totalTime > 0 ? (femaleTime / totalTime) * 100 : 0;
  
          const dayData = [
            { Category: "Dones", Percentage: femalePercentage.toFixed(2) },
            { Category: "Homes", Percentage: malePercentage.toFixed(2) },
          ];
  
          XLSX.utils.sheet_add_aoa(
            worksheet,
            [
              [`Data: ${dayLabel}`, `Total de temps: ${formatTime(totalTime)}`],
              ["Category", "Percentage"],
            ],
            { origin: `A${rowStart}` }
          );
  
          XLSX.utils.sheet_add_json(worksheet, dayData, {
            origin: `A${rowStart + 3}`,
            skipHeader: true,
          });
  
          rowStart += 6;
        });
  
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizeSheetName(timeLabel)
        );
      });
    }
  
    if (workbook.SheetNames.length === 0) {
      console.error("No sheets were created");
      return;
    }
  
    XLSX.writeFile(
      workbook,
      `Stacked_Gender_Distribution_Including_TopSpeaker_${selectedFilter}.xlsx`
    );
  };
  

  //excel top speaker

  const handleExportExcludingTopSpeaker = async () => {
    const workbook = XLSX.utils.book_new();

    const filteredJobsForExport = filteredJobs.filter((job) => {
      const date = parse(job.date, "dd/MM/yyyy", new Date());
      const formattedDate = format(date, "dd/MM/yyyy");
      return selectedDates.some(
        (selectedDate) => selectedDate.value === formattedDate
      );
    });

    for (const job of filteredJobsForExport) {
      const maleResults = job.results[0].filter(
        (result) => result.gender === "MALE"
      );
      const femaleResults = job.results[0].filter(
        (result) => result.gender === "FEMALE"
      );

      if (maleResults.length === 0 && femaleResults.length === 0) {
        console.warn(
          `No hay resultados de género para el programa: ${job.titol}`
        );
        continue;
      }

      const maleSpeakers = maleResults.reduce((acc, result) => {
        const speaker = result.speaker;
        const timeSpoken = result.end - result.start;

        if (speaker && !isNaN(timeSpoken)) {
          acc[speaker] = (acc[speaker] || 0) + timeSpoken;
        } else {
          console.warn(
            `Datos incompletos para resultado masculino en ${job.titol}`,
            result
          );
        }
        return acc;
      }, {});

      const femaleSpeakers = femaleResults.reduce((acc, result) => {
        const speaker = result.speaker;
        const timeSpoken = result.end - result.start;

        if (speaker && !isNaN(timeSpoken)) {
          acc[speaker] = (acc[speaker] || 0) + timeSpoken;
        } else {
          console.warn(
            `Datos incompletos para resultado femenino en ${job.titol}`,
            result
          );
        }
        return acc;
      }, {});

      const totalMaleTime = Object.values(maleSpeakers).reduce(
        (sum, time) => sum + time,
        0
      );
      const totalFemaleTime = Object.values(femaleSpeakers).reduce(
        (sum, time) => sum + time,
        0
      );

      const topMaleSpeaker = Object.entries(maleSpeakers).reduce(
        (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
        { speaker: null, time: 0 }
      );

      const topFemaleSpeaker = Object.entries(femaleSpeakers).reduce(
        (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
        { speaker: null, time: 0 }
      );

      const topSpeaker =
        topMaleSpeaker.time > topFemaleSpeaker.time
          ? topMaleSpeaker
          : topFemaleSpeaker;
      const topSpeakerCategory =
        topSpeaker === topMaleSpeaker ? "Homes" : "Dones";

      const remainingMaleTime =
        totalMaleTime - (topSpeakerCategory === "Homes" ? topSpeaker.time : 0);
      const remainingFemaleTime =
        totalFemaleTime -
        (topSpeakerCategory === "Dones" ? topSpeaker.time : 0);
      const totalRemainingTime = remainingMaleTime + remainingFemaleTime;

      const recalculatedData = [
        {
          Category: "Dones",
          Percentage:
            totalRemainingTime > 0
              ? ((remainingFemaleTime / totalRemainingTime) * 100).toFixed(2)
              : 0,
        },
        {
          Category: "Homes",
          Percentage:
            totalRemainingTime > 0
              ? ((remainingMaleTime / totalRemainingTime) * 100).toFixed(2)
              : 0,
        },
      ];

      const totalTime = calculateTotalTimeProgram(job);
      const formattedTime = formatTime(totalTime);

      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Temps: ${formattedTime}`],
          [`Top Speaker exclòs: ${topSpeakerCategory} - ${topSpeaker.speaker}`],
          ["Category", "Percentage"],
        ],
        { origin: "A1" }
      );
      XLSX.utils.sheet_add_json(worksheet, recalculatedData, {
        origin: "A6",
        skipHeader: true,
      });

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        sanitizeSheetName(`${job.titol}`)
      );
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Stacked_Gender_Distribution_Excluding_TopSpeaker_ByDay.xlsx"
    );
  };

  /* const handleExportExcludingTopSpeakerByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();

    let filteredPrograms = [];
    if (selectedFilter === "SETMANA - DIES") {
        filteredPrograms = filterByWeekAndDays(filteredJobs, selectedDates);
    } else if (selectedFilter === "MES - DIES") {
        filteredPrograms = filterByMonthAndDays(filteredJobs, selectedDates);
    } else {
        filteredPrograms = filteredJobs.filter((job) => {
            const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
            const weekNumber = getISOWeek(jobDate);
            const monthNumber = getMonth(jobDate) + 1;

            return selectedDates.some((date) => {
                if (selectedFilter === "SETMANA") {
                    const weekFromSelection = parseInt(date.value.split(" ")[1]);
                    return weekNumber === weekFromSelection;
                } else if (selectedFilter === "MES") {
                    const monthFromSelection = parseInt(date.value.split(" ")[1]);
                    return monthNumber === monthFromSelection;
                }
                return false;
            });
        });
    }

    if (filteredPrograms.length === 0) {
        console.error("No data to export");
        return;
    }

    let aggregatedData = {};

    filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel = selectedFilter.startsWith("SETMANA")
            ? `Setmana ${getISOWeek(jobDate)}`
            : `Mes ${getMonth(jobDate) + 1}`;
        const dayLabel = format(jobDate, "dd/MM/yyyy");

        if (selectedFilter === "SETMANA - DIES" || selectedFilter === "MES - DIES") {
            if (!aggregatedData[timeLabel]) {
                aggregatedData[timeLabel] = { days: {}, programTitle: job.titol };
            }
            if (!aggregatedData[timeLabel].days[dayLabel]) {
                aggregatedData[timeLabel].days[dayLabel] = { maleTime: 0, femaleTime: 0, totalTime: 0 };
            }
        } else {
            if (!aggregatedData[timeLabel]) {
                aggregatedData[timeLabel] = { maleTime: 0, femaleTime: 0, totalTime: 0, programTitle: job.titol };
            }
        }

        const maleResults = job.results[0].filter((result) => result.gender === "MALE");
        const femaleResults = job.results[0].filter((result) => result.gender === "FEMALE");

        const maleSpeakers = maleResults.reduce((acc, result) => {
            const speaker = result.speaker;
            const timeSpoken = result.end - result.start;
            acc[speaker] = (acc[speaker] || 0) + timeSpoken;
            return acc;
        }, {});

        const femaleSpeakers = femaleResults.reduce((acc, result) => {
            const speaker = result.speaker;
            const timeSpoken = result.end - result.start;
            acc[speaker] = (acc[speaker] || 0) + timeSpoken;
            return acc;
        }, {});

        const totalMaleTime = Object.values(maleSpeakers).reduce((sum, time) => sum + time, 0);
        const totalFemaleTime = Object.values(femaleSpeakers).reduce((sum, time) => sum + time, 0);

        const topMaleSpeaker = Object.entries(maleSpeakers).reduce(
            (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
            { speaker: null, time: 0 }
        );

        const topFemaleSpeaker = Object.entries(femaleSpeakers).reduce(
            (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
            { speaker: null, time: 0 }
        );

        const topSpeaker = topMaleSpeaker.time > topFemaleSpeaker.time ? topMaleSpeaker : topFemaleSpeaker;
        const topSpeakerCategory = topSpeaker === topMaleSpeaker ? "Homes" : "Dones";

        const remainingMaleTime = totalMaleTime - (topSpeakerCategory === "Homes" ? topSpeaker.time : 0);
        const remainingFemaleTime = totalFemaleTime - (topSpeakerCategory === "Dones" ? topSpeaker.time : 0);

        if (selectedFilter === "SETMANA - DIES" || selectedFilter === "MES - DIES") {
            aggregatedData[timeLabel].days[dayLabel].maleTime += remainingMaleTime;
            aggregatedData[timeLabel].days[dayLabel].femaleTime += remainingFemaleTime;
            aggregatedData[timeLabel].days[dayLabel].totalTime += remainingMaleTime + remainingFemaleTime;
        } else {
            aggregatedData[timeLabel].maleTime += remainingMaleTime;
            aggregatedData[timeLabel].femaleTime += remainingFemaleTime;
            aggregatedData[timeLabel].totalTime += remainingMaleTime + remainingFemaleTime;
        }
    });

    Object.keys(aggregatedData).forEach((timeLabel) => {
        const { maleTime, femaleTime, totalTime, days, programTitle } = aggregatedData[timeLabel];

        if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
            const malePercentage = totalTime > 0 ? (maleTime / totalTime) * 100 : 0;
            const femalePercentage = totalTime > 0 ? (femaleTime / totalTime) * 100 : 0;

            const summaryData = [
                [`Programa: ${programTitle}`],
                //[`Resum de ${timeLabel} (excloent Top Speaker)`],
                //[`Total de temps: ${formatTime(totalTime)}`],
                ["Category", "Percentage"],
                ["Dones", femalePercentage.toFixed(2)],
                ["Homes", malePercentage.toFixed(2)],
            ];

            const worksheet = XLSX.utils.aoa_to_sheet(summaryData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sanitizeSheetName(timeLabel));
        } else {
            Object.keys(days).sort((a, b) => new Date(a) - new Date(b)).forEach((dayLabel) => {
                const { maleTime: dayMaleTime, femaleTime: dayFemaleTime, totalTime: dayTotalTime } = days[dayLabel];
                const dayTotal = dayMaleTime + dayFemaleTime;
                const malePercentage = dayTotal > 0 ? (dayMaleTime / dayTotal) * 100 : 0;
                const femalePercentage = dayTotal > 0 ? (dayFemaleTime / dayTotal) * 100 : 0;

                const dayData = [
                    [`Programa: ${programTitle}`],
                    [`Data: ${dayLabel}`],
                    //[`Total de temps: ${formatTime(dayTotal)}`],
                    ["Category", "Percentage"],
                    ["Homes", malePercentage.toFixed(2)],
                    ["Dones", femalePercentage.toFixed(2)],
                ];

                const worksheet = XLSX.utils.aoa_to_sheet(dayData);
                const sheetName = sanitizeSheetName(`${timeLabel} - ${dayLabel}`);
                XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
            });
        }
    });

    if (workbook.SheetNames.length === 0) {
        console.error("No sheets were created");
        return;
    }

    XLSX.writeFile(workbook, `Stacked_Gender_Distribution_Excluding_TopSpeaker_${selectedFilter}.xlsx`);
};
 */

const handleExportExcludingTopSpeakerByWeekOrMonth = async () => {
  const workbook = XLSX.utils.book_new();

  let filteredPrograms = [];
  if (selectedFilter === "SETMANA - DIES") {
    filteredPrograms = filterByWeekAndDays(filteredJobs, selectedDates);
  } else if (selectedFilter === "MES - DIES") {
    filteredPrograms = filterByMonthAndDays(filteredJobs, selectedDates);
  } else {
    filteredPrograms = filteredJobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      const weekNumber = getISOWeek(jobDate);
      const monthNumber = getMonth(jobDate) + 1;

      return selectedDates.some((date) => {
        if (selectedFilter === "SETMANA") {
          const weekFromSelection = parseInt(date.value.split(" ")[1]);
          return weekNumber === weekFromSelection;
        } else if (selectedFilter === "MES") {
          const monthFromSelection = parseInt(date.value.split(" ")[1]);
          return monthNumber === monthFromSelection;
        }
        return false;
      });
    });
  }

  if (filteredPrograms.length === 0) {
    console.error("No data to export");
    return;
  }

  let aggregatedData = {};

  filteredPrograms.forEach((job) => {
    const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
    const timeLabel =
      selectedFilter.startsWith("SETMANA")
        ? `Setmana ${getISOWeek(jobDate)}`
        : `Mes ${getMonth(jobDate) + 1}`;
    const dayLabel = format(jobDate, "dd/MM/yyyy");

    if (
      selectedFilter === "SETMANA - DIES" ||
      selectedFilter === "MES - DIES"
    ) {
      if (!aggregatedData[timeLabel]) {
        aggregatedData[timeLabel] = { days: {}, programTitle: job.titol };
      }
      if (!aggregatedData[timeLabel].days[dayLabel]) {
        aggregatedData[timeLabel].days[dayLabel] = {
          maleTime: 0,
          femaleTime: 0,
          totalTime: 0,
        };
      }
    } else {
      if (!aggregatedData[timeLabel]) {
        aggregatedData[timeLabel] = {
          maleTime: 0,
          femaleTime: 0,
          totalTime: 0,
          programTitle: job.titol,
        };
      }
    }

    const maleResults = job.results[0].filter(
      (result) => result.gender === "MALE"
    );
    const femaleResults = job.results[0].filter(
      (result) => result.gender === "FEMALE"
    );

    const maleSpeakers = maleResults.reduce((acc, result) => {
      const speaker = result.speaker;
      const timeSpoken = result.end - result.start;
      acc[speaker] = (acc[speaker] || 0) + timeSpoken;
      return acc;
    }, {});

    const femaleSpeakers = femaleResults.reduce((acc, result) => {
      const speaker = result.speaker;
      const timeSpoken = result.end - result.start;
      acc[speaker] = (acc[speaker] || 0) + timeSpoken;
      return acc;
    }, {});

    const totalMaleTime = Object.values(maleSpeakers).reduce(
      (sum, time) => sum + time,
      0
    );
    const totalFemaleTime = Object.values(femaleSpeakers).reduce(
      (sum, time) => sum + time,
      0
    );

    const topMaleSpeaker = Object.entries(maleSpeakers).reduce(
      (max, [speaker, time]) =>
        time > max.time ? { speaker, time } : max,
      { speaker: null, time: 0 }
    );

    const topFemaleSpeaker = Object.entries(femaleSpeakers).reduce(
      (max, [speaker, time]) =>
        time > max.time ? { speaker, time } : max,
      { speaker: null, time: 0 }
    );

    const topSpeaker =
      topMaleSpeaker.time > topFemaleSpeaker.time
        ? topMaleSpeaker
        : topFemaleSpeaker;
    const topSpeakerCategory =
      topSpeaker === topMaleSpeaker ? "Homes" : "Dones";

    const remainingMaleTime =
      totalMaleTime - (topSpeakerCategory === "Homes" ? topSpeaker.time : 0);
    const remainingFemaleTime =
      totalFemaleTime -
      (topSpeakerCategory === "Dones" ? topSpeaker.time : 0);
    const totalRemainingTime = remainingMaleTime + remainingFemaleTime;

    if (
      selectedFilter === "SETMANA - DIES" ||
      selectedFilter === "MES - DIES"
    ) {
      aggregatedData[timeLabel].days[dayLabel].maleTime += remainingMaleTime;
      aggregatedData[timeLabel].days[dayLabel].femaleTime += remainingFemaleTime;
      aggregatedData[timeLabel].days[dayLabel].totalTime += totalRemainingTime;
    } else {
      aggregatedData[timeLabel].maleTime += remainingMaleTime;
      aggregatedData[timeLabel].femaleTime += remainingFemaleTime;
      aggregatedData[timeLabel].totalTime += totalRemainingTime;
    }
  });

  Object.keys(aggregatedData).forEach((timeLabel) => {
    const { maleTime, femaleTime, totalTime, days, programTitle } =
      aggregatedData[timeLabel];

    if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
      const malePercentage =
        totalTime > 0 ? (maleTime / totalTime) * 100 : 0;
      const femalePercentage =
        totalTime > 0 ? (femaleTime / totalTime) * 100 : 0;

      const summaryData = [
        [`Programa: ${programTitle}`],
        [`Resum de ${timeLabel} (excloent Top Speaker)`],
        //[`Total de temps: ${formatTime(totalTime)}`],
        ["Category", "Percentage"],
        ["Dones", femalePercentage.toFixed(2)],
        ["Homes", malePercentage.toFixed(2)],
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(summaryData);
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        sanitizeSheetName(timeLabel)
      );
    } else {
      Object.keys(days)
        .sort((a, b) => new Date(a) - new Date(b))
        .forEach((dayLabel) => {
          const {
            maleTime: dayMaleTime,
            femaleTime: dayFemaleTime,
            totalTime: dayTotalTime,
          } = days[dayLabel];

          const malePercentage =
            dayTotalTime > 0 ? (dayMaleTime / dayTotalTime) * 100 : 0;
          const femalePercentage =
            dayTotalTime > 0 ? (dayFemaleTime / dayTotalTime) * 100 : 0;

          const dayData = [
            [`Programa: ${programTitle}`],
            [`Data: ${dayLabel}`],
            //[`Total de temps: ${formatTime(dayTotalTime)}`],
            ["Category", "Percentage"],
            ["Homes", malePercentage.toFixed(2)],
            ["Dones", femalePercentage.toFixed(2)],
          ];

          const worksheet = XLSX.utils.aoa_to_sheet(dayData);
          const sheetName = sanitizeSheetName(`${timeLabel} - ${dayLabel}`);
          XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });
    }
  });

  if (workbook.SheetNames.length === 0) {
    console.error("No sheets were created");
    return;
  }

  XLSX.writeFile(
    workbook,
    `Stacked_Gender_Distribution_Excluding_TopSpeaker_${selectedFilter}.xlsx`
  );
};


  

  return (
    <div id="chart" className="w-full max-w-screen-lg mx-auto">
      <div className="mb-4">
        <h1 className="text-xs text-black">
          Resultats acomulats per dia, setmana o mes
        </h1>
      </div>
      <div className="flex flex-row mb-4">
        <FilterSelect
          options={[
            { value: "day", label: "Dia" },
            { value: "SETMANA", label: "Setmana" },
            { value: "SETMANA - DIES", label: "Setmana - Dies" },
            { value: "MES", label: "Mes" },
            { value: "MES - DIES", label: "Mes - Dies" },
          ]}
          selectedValue={selectedFilter}
          onChange={(option) => handleFilterChange(option.value)}
        />
        {selectedFilter && selectedFilter !== "day" && (
          <div className="mb-4">
            <MultiSelect
              options={uniqueWeeksAndMonths}
              selectedValues={selectedDates}
              onChange={(selectedOptions) => {
                const updatedSelectedDates = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];
                setSelectedDates(updatedSelectedDates);
              }}
              displayValue={(option) => option.label}
            />
          </div>
        )}
        {selectedFilter === "day" && (
          <div className="mb-4">
            <MultiSelect
              options={uniqueDays}
              selectedValues={selectedDates}
              onChange={(selectedOptions) => {
                const updatedSelectedDates = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];
                setSelectedDates(updatedSelectedDates);
              }}
              displayValue={(option) => option.label}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col justify-center space-y-6 md:space-y-8 lg:space-y-10">
        <div className="flex justify-center">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={chartOptions.chart.height}
            width={chartOptions.chart.width}
          />
        </div>
      </div>
      <div className="m-4 flex flex-row gap-4 items-start">
        <button
          onClick={
            selectedFilter === "day"
              ? handleExportByDay
              : handleExportByWeekOrMonth
          }
          className={`text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400`}
        >
          {selectedFilter === "day"
            ? "Excel inclou speakers"
            : "Excel inclou speakers"}
        </button>
        <button
          onClick={
            selectedFilter === "day"
              ? handleExportExcludingTopSpeaker
              : handleExportExcludingTopSpeakerByWeekOrMonth
          }
          className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Excel exclou top speaker
        </button>
      </div>
    </div>
  );
};

export default StackedChart;
