// COMPONENTE CON EL EXCEL EN BATCH

import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { parse, getMonth, getISOWeek } from "date-fns";
import { useJobs } from "../../hooks/useJobs/useJobs";
import Button from "../Button/Button";
import HeatmapChart from "./HeatmapChart";
import SpeakersChart from "./SpeakersChart";
import Header from "../Header/Header";
import FilterSelect from "../FilterSelect/FilterSelect";
import MultiSelect from "../MultiSelect/MultiSelect";
import StackedChart from "./StackedChart";
import LogoutButton from "../LogoutButton/Logoutbutton";

const Charts = ({ selectedJobId }) => {
  const { getJobs, getJobById } = useJobs();

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [genderStats, setGenderStats] = useState({ male: 0, female: 0 });
  const [generalSelectedProgram, setGeneralSelectedProgram] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("day");
  const [selectedDates, setSelectedDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([0, 0]);
  //estados para el excel
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [filterText, setFilterText] = useState("");
  //estado para calcular el tiempo total de los programas
  const [totalProgramTime, setTotalProgramTime] = useState(0);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        setJobs(response);
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchJobs();
  }, [getJobs]);

  useEffect(() => {
    if (generalSelectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol.includes(generalSelectedProgram)
      );
      setFilteredJobs(filtered);
      setSelectedProgram("");
    } else {
      setFilteredJobs([]);
      setSelectedProgram("");
    }
  }, [generalSelectedProgram, jobs]);

 /*  useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedDates.length || !selectedFilter || !filteredJobs.length) {
        setGenderStats({ male: 0, female: 0 });
        setChartSeries([0, 0]);
        return;
      }

      let maleCount = 0;
      let femaleCount = 0;
      let jobsInSelectedRange = [];

      if (selectedFilter === "day") {
        const selectedDate = selectedDates[0];
        jobsInSelectedRange = filteredJobs.filter(
          (job) => job.date === selectedDate
        );
      } else if (selectedFilter === "week") {
        jobsInSelectedRange = filteredJobs.filter((job) => {
          const weekNumber = getISOWeek(
            parse(job.date, "dd/MM/yyyy", new Date())
          );
          return selectedDates.some((date) => date.value === weekNumber);
        });
      } else if (selectedFilter === "month") {
        jobsInSelectedRange = filteredJobs.filter((job) => {
          const monthNumber =
            getMonth(parse(job.date, "dd/MM/yyyy", new Date())) + 1;
          return selectedDates.some((date) => date.value === monthNumber);
        });
      }

      for (const job of jobsInSelectedRange) {
        try {
          job.results[0].forEach((result) => {
            if (result.gender === "MALE") {
              maleCount++;
            } else if (result.gender === "FEMALE") {
              femaleCount++;
            }
          });
        } catch (error) {
          console.error(`Error processing job ID ${job.id}:`, error.message);
        }
      }

      const totalCount = maleCount + femaleCount;
      if (totalCount > 0) {
        const malePercentage = (maleCount / totalCount) * 100;
        const femalePercentage = (femaleCount / totalCount) * 100;

        setGenderStats({
          male: malePercentage.toFixed(2),
          female: femalePercentage.toFixed(2),
        });

        setChartSeries([
          parseFloat(malePercentage.toFixed(2)),
          parseFloat(femalePercentage.toFixed(2)),
        ]);
      } else {
        setGenderStats({ male: 0, female: 0 });
        setChartSeries([0, 0]);
      }
    };

    calculateGenderStats();
  }, [selectedFilter, selectedDates, filteredJobs]); */

  useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedDates.length || !selectedFilter || !filteredJobs.length) {
        setGenderStats({ male: 0, female: 0 });
        setChartSeries([0, 0]);
        return;
      }
  
      let maleTime = 0;
      let femaleTime = 0;
      let jobsInSelectedRange = [];
  
      if (selectedFilter === "day") {
        const selectedDate = selectedDates[0];
        jobsInSelectedRange = filteredJobs.filter(
          (job) => job.date === selectedDate
        );
      } else if (selectedFilter === "week") {
        jobsInSelectedRange = filteredJobs.filter((job) => {
          const weekNumber = getISOWeek(
            parse(job.date, "dd/MM/yyyy", new Date())
          );
          return selectedDates.some((date) => date.value === weekNumber);
        });
      } else if (selectedFilter === "month") {
        jobsInSelectedRange = filteredJobs.filter((job) => {
          const monthNumber =
            getMonth(parse(job.date, "dd/MM/yyyy", new Date())) + 1;
          return selectedDates.some((date) => date.value === monthNumber);
        });
      }
  
      for (const job of jobsInSelectedRange) {
        try {
          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTime += duration;
            } else if (result.gender === "FEMALE") {
              femaleTime += duration;
            }
          });
        } catch (error) {
          console.error(`Error processing job ID ${job.id}:`, error.message);
        }
      }
  
      const totalTime = maleTime + femaleTime;
      if (totalTime > 0) {
        const malePercentage = (maleTime / totalTime) * 100;
        const femalePercentage = (femaleTime / totalTime) * 100;
  
        setGenderStats({
          male: malePercentage.toFixed(2),
          female: femalePercentage.toFixed(2),
        });
  
        setChartSeries([
          parseFloat(malePercentage.toFixed(2)),
          parseFloat(femalePercentage.toFixed(2)),
        ]);
      } else {
        setGenderStats({ male: 0, female: 0 });
        setChartSeries([0, 0]);
      }
    };
  
    calculateGenderStats();
  }, [selectedFilter, selectedDates, filteredJobs]);

  
  const formatJobTitle = (titol) => {
    const parts = titol.split(" - ");
    return parts[0].trim();
  };

  const getUniqueWeeksAndMonths = () => {
    let uniqueWeeks = new Set();
    let uniqueMonths = new Set();

    filteredJobs.forEach((job) => {
      const dateStr = job.date;
      if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return;
      const date = parse(dateStr, "dd/MM/yyyy", new Date());

      if (selectedFilter === "week") {
        uniqueWeeks.add(getISOWeek(date));
      } else if (selectedFilter === "month") {
        uniqueMonths.add(getMonth(date) + 1);
      }
    });

    return selectedFilter === "week"
      ? Array.from(uniqueWeeks)
      : Array.from(uniqueMonths);
  };

  const uniqueDates = getUniqueWeeksAndMonths().map((dateValue) => ({
    value: dateValue,
    label:
      selectedFilter === "week" ? `Setmana ${dateValue}` : `Mes ${dateValue}`,
  }));

  const programOptions = Array.from(
    new Set(jobs.map((job) => formatJobTitle(job.titol)))
  ).map((titulo) => ({ value: titulo, label: titulo }));

  const filteredProgramOptions = filteredJobs.map((job) => ({
    value: job.titol,
    label: job.titol,
  }));

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      type: "pie",
      width: "100%",
    },
    labels: ["HOMES", "DONES"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const sortedFilteredJobs = filteredJobs.sort((a, b) => {
    const dateA = parse(a.date, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date, "dd/MM/yyyy", new Date());

    return dateA - dateB;
  });

  const handleProgramChange = (program) => {
    setSelectedProgram(program);

    if (selectedFilter === "day") {
      const job = filteredJobs.find((job) => job.titol === program);
      if (job) {
        setSelectedDates([job.date]);
      }
    }
  };

  const handleGeneralProgramChange = (program) => {
    setGeneralSelectedProgram(program);
    const filtered = jobs.filter((job) => job.titol.includes(program));
    setFilteredJobs(filtered);
    setSelectedProgram("");
    setSelectedDates([]);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "day" && selectedProgram) {
      setSelectedDates([selectedProgram]);
    } else {
      setSelectedDates([]);
    }
  };

  const calculateTotalTimeProgram = (job) => {
    let totalTime = 0;

    for (let i = 0; i < job.results.length; i++) {
      const segmentos = job.results[i];

      for (let j = 0; j < segmentos.length; j++) {
        const segmento = segmentos[j];
        totalTime += segmento.end - segmento.start;
      }
    }

    return totalTime;
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toFixed(2);

    return `${hours} hores, ${minutes} minuts i ${seconds} segons`;
  };

  useEffect(() => {
    if (selectedProgram) {
      const selectedJob = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (selectedJob) {
        const totalTime = calculateTotalTimeProgram(selectedJob);

        setTotalProgramTime(totalTime);
      }
    }
  }, [selectedProgram, filteredJobs]);

  //descagrar excel

  const sanitizeSheetName = (name) => {
    return name.replace(/[:\\\/\?\*\[\]]/g, "").substring(0, 31);
  };

  const handleSelectPrograms = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const ids = selectedOptions.map((option) => option.value);
    setSelectedProgramIds(ids);
  };

  const handleExportByDay = async () => {
    const workbook = XLSX.utils.book_new();

    const normalizedProgramName = generalSelectedProgram.trim().toLowerCase();
    const selectedProgramElements = jobs.filter((job) => {
      const jobTitleNormalized = job.titol.trim().toLowerCase();
      return jobTitleNormalized.includes(normalizedProgramName);
    });

    for (const job of selectedProgramElements) {
      try {
        let maleCount = 0;
        let femaleCount = 0;

        job.results[0].forEach((result) => {
          if (result.gender === "MALE") {
            maleCount++;
          } else if (result.gender === "FEMALE") {
            femaleCount++;
          }
        });

        const totalJobCount = maleCount + femaleCount;
        const malePercentage =
          totalJobCount > 0 ? (maleCount / totalJobCount) * 100 : 0;
        const femalePercentage =
          totalJobCount > 0 ? (femaleCount / totalJobCount) * 100 : 0;

        const totalTime = calculateTotalTimeProgram(job);
        const formattedTime = formatTime(totalTime);

        const programData = [
          {
            Category: "Homes",
            Percentage: malePercentage.toFixed(2),
            /* Count: maleCount, */
          },
          {
            Category: "Dones",
            Percentage: femalePercentage.toFixed(2),
            /*  Count: femaleCount, */
          },
        ];

        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Temps: ${formattedTime}`],
          ["Category", "Percentage" /* , "Count" */],
        ]);

        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A4",
          skipHeader: true,
        });

        const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(`Error processing program ID ${job.id}:`, error.message);
      }
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByDay_Including_Top_Speaker.xlsx"
    );
  };

  const handleExportByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();

    const filteredPrograms = filteredJobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      if (selectedFilter === "week") {
        const weekNumber = getISOWeek(jobDate);
        return selectedDates.some((date) => date.value === weekNumber);
      } else if (selectedFilter === "month") {
        const monthNumber = getMonth(jobDate) + 1;
        return selectedDates.some((date) => date.value === monthNumber);
      }
      return false;
    });

    let totalProgramTime = 0;
    let totalMaleCount = 0;
    let totalFemaleCount = 0;

    const selectedDatesString = selectedDates
      .map((date) => date.value)
      .join(", ");

    for (const job of filteredPrograms) {
      try {
        let maleCount = 0;
        let femaleCount = 0;

        job.results[0].forEach((result) => {
          if (result.gender === "MALE") {
            maleCount++;
          } else if (result.gender === "FEMALE") {
            femaleCount++;
          }
        });

        totalMaleCount += maleCount;
        totalFemaleCount += femaleCount;

        const totalJobCount = maleCount + femaleCount;
        const malePercentage =
          totalJobCount > 0 ? (maleCount / totalJobCount) * 100 : 0;
        const femalePercentage =
          totalJobCount > 0 ? (femaleCount / totalJobCount) * 100 : 0;

        const jobTime = calculateTotalTimeProgram(job);
        totalProgramTime += jobTime;

        const programData = [
          {
            Category: "Homes",
            Percentage: malePercentage.toFixed(2),
          },
          {
            Category: "Dones",
            Percentage: femalePercentage.toFixed(2),
          },
        ];

        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Temps: ${formatTime(jobTime)}`],
          [`Setmana/Mes: ${selectedDatesString}`],
          ["Category", "Percentage"],
        ]);
        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A4",
          skipHeader: true,
        });

        const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(`Error processing program ID ${job.id}:`, error.message);
      }
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }

    const totalJobCount = totalMaleCount + totalFemaleCount;
    const totalMalePercentage =
      totalJobCount > 0 ? (totalMaleCount / totalJobCount) * 100 : 0;
    const totalFemalePercentage =
      totalJobCount > 0 ? (totalFemaleCount / totalJobCount) * 100 : 0;

    const summaryData = [
      ["Resum de les setmanes/mesos"],
      [`Temps : ${formatTime(totalProgramTime)}`],
      [`Setmana/Mes: ${selectedDatesString}`],
      [`Percentatge homes: ${totalMalePercentage.toFixed(2)}%`],
      [`Percentatge dones: ${totalFemalePercentage.toFixed(2)}%`],
    ];

    const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Resum Agregat");

    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByWeekOrMonth_Including_Top_Speaker.xlsx"
    );
  };

  const handleExportExcludingTopSpeaker = async () => {
    const workbook = XLSX.utils.book_new();

    const filteredPrograms = filteredJobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      if (selectedFilter === "week") {
        const weekNumber = getISOWeek(jobDate);
        return selectedDates.some((date) => date.value === weekNumber);
      } else if (selectedFilter === "month") {
        const monthNumber = getMonth(jobDate) + 1;
        return selectedDates.some((date) => date.value === monthNumber);
      }
      return false;
    });

    let totalProgramTimeExcludingTop = 0;
    let totalMaleTimeExcludingTop = 0;
    let totalFemaleTimeExcludingTop = 0;

    const selectedDatesString = selectedDates
      .map((date) => date.value)
      .join(", ");

    for (const job of filteredPrograms) {
      try {
        const statsExcludingTop = calculateStatsExcludingTopSpeaker(job);
        if (statsExcludingTop) {
          const malePercentageExcludingTop = statsExcludingTop.malePercentage;
          const femalePercentageExcludingTop =
            statsExcludingTop.femalePercentage;
          const jobTimeExcludingTop = statsExcludingTop.totalTime;

          totalProgramTimeExcludingTop += jobTimeExcludingTop;
          totalMaleTimeExcludingTop +=
            (malePercentageExcludingTop / 100) * jobTimeExcludingTop;
          totalFemaleTimeExcludingTop +=
            (femalePercentageExcludingTop / 100) * jobTimeExcludingTop;

          const worksheet = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(worksheet, [
            [`Programa: ${job.titol}`],
            [`Data: ${job.date}`],
            [`Temps: ${formatTime(jobTimeExcludingTop)}`],
            [`Top Speaker exclòs: ${statsExcludingTop.originalTopSpeaker}`],
            ["Category", "Percentage"],
            ["Homes", malePercentageExcludingTop.toFixed(2)],
            ["Dones", femalePercentageExcludingTop.toFixed(2)],
          ]);

          const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
          XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
        }
      } catch (error) {
        console.error(`Error processing program ID ${job.id}:`, error.message);
      }
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }

    const totalJobCountExcludingTop =
      totalMaleTimeExcludingTop + totalFemaleTimeExcludingTop;
    const totalMalePercentageExcludingTop =
      totalJobCountExcludingTop > 0
        ? (totalMaleTimeExcludingTop / totalJobCountExcludingTop) * 100
        : 0;
    const totalFemalePercentageExcludingTop =
      totalJobCountExcludingTop > 0
        ? (totalFemaleTimeExcludingTop / totalJobCountExcludingTop) * 100
        : 0;

    const summaryData = [
      ["Resum de les setmanes/mesos (excloent top speaker)"],
      [`Temps: ${formatTime(totalProgramTimeExcludingTop)}`],
      [`Setmana/Mes: ${selectedDatesString}`],
      [
        `Percentatge homes ajustat: ${totalMalePercentageExcludingTop.toFixed(
          2
        )}%`,
      ],
      [
        `Percentatge dones ajustat: ${totalFemalePercentageExcludingTop.toFixed(
          2
        )}%`,
      ],
    ];

    const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Resum Agregat");

    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByWeekOrMonth_Excluding_Top_Speaker.xlsx"
    );
  };

  const handleFilterExcelChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const filteredJobOptions = jobs.filter((job) =>
    job.titol.toLowerCase().includes(filterText)
  );

  //excel excloute top speaker

  const calculateStatsExcludingTopSpeaker = (job) => {
    if (!job || !job.results || !job.results[0]) return null;

    const speakerStats = {};
    let totalTime = 0;

    job.results[0].forEach((result) => {
      const duration = result.end - result.start;
      totalTime += duration;

      if (!speakerStats[result.speaker]) {
        speakerStats[result.speaker] = {
          time: 0,
          gender: result.gender,
        };
      }
      speakerStats[result.speaker].time += duration;
    });

    let topSpeaker = {
      speaker: "",
      time: 0,
    };

    Object.entries(speakerStats).forEach(([speaker, stats]) => {
      if (stats.time > topSpeaker.time) {
        topSpeaker = {
          speaker,
          time: stats.time,
        };
      }
    });

    let maleTime = 0;
    let femaleTime = 0;
    let newTotalTime = 0;

    job.results[0].forEach((result) => {
      if (result.speaker !== topSpeaker.speaker) {
        const duration = result.end - result.start;
        newTotalTime += duration;

        if (result.gender === "MALE") {
          maleTime += duration;
        } else if (result.gender === "FEMALE") {
          femaleTime += duration;
        }
      }
    });

    const malePercentage = (maleTime / newTotalTime) * 100;
    const femalePercentage = (femaleTime / newTotalTime) * 100;

    return {
      totalTime: newTotalTime,
      malePercentage,
      femalePercentage,
      originalTopSpeaker: topSpeaker.speaker,
      originalTotalTime: totalTime,
    };
  };

  const handleExportStatsExcludingTopSpeaker = () => {
    const workbook = XLSX.utils.book_new();

    const programsToProcess =
      selectedProgramIds.length > 0
        ? filteredJobs.filter((job) => selectedProgramIds.includes(job.id))
        : filteredJobs;

    for (const job of programsToProcess) {
      try {
        const stats = calculateStatsExcludingTopSpeaker(job);
        if (!stats) continue;

        const programData = [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Temps: ${formatTime(stats.originalTotalTime)}`],
          [`Top Speaker exclòs: ${stats.originalTopSpeaker}`],
          ["Categoria", "Percentatge sense top speaker"],
          ["Homes", `${stats.malePercentage.toFixed(2)}%`],
          ["Dones", `${stats.femalePercentage.toFixed(2)}%`],
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(programData);

        const columnWidths = [{ wch: 40 }, { wch: 20 }];
        worksheet["!cols"] = columnWidths;

        const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(`Error processing program ID ${job.id}:`, error.message);
      }
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No hay datos para exportar");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByDay_Excluding_Top_Speaker.xlsx"
    );
  };

  return (
    <>
      <LogoutButton />
      <Header />
      <div
        id="chart"
        className="w-full max-w-screen-lg mx-auto overflow-x-auto"
      >
        <main className="p-4 min-h-screen w-full">
          <div className="flex justify-end mb-4">
            <Button to="/">
              <img src="/img/icon_arrow.png" alt="Torna" />
            </Button>
          </div>
          <div>
            <div className="m-12">
              <span className="flex text-black text-base font-bold justify-center">
                Selecciona primer el programa que vols visualitzar i
                després el dia a través d’aquests filtres: 
              </span>
              <FilterSelect
                options={programOptions}
                selectedValue={generalSelectedProgram}
                onChange={(option) => handleGeneralProgramChange(option.value)}
              />
              {generalSelectedProgram && (
                <div>
                  <FilterSelect
                    options={filteredProgramOptions}
                    selectedValue={selectedProgram}
                    onChange={(option) => handleProgramChange(option.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex justify-center">
              <SpeakersChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
            <h1 className="text-xs text-black">
              Percentatges de sexe per dia, setmana o mes
            </h1>
            <span className="italic text-xs text-black">
              Selecciona entre "dia", "setmana" o "mes" per obtindre resultats
              del programa seleccionat anteriorment.
            </span>
          </div>
          <div className="flex flex-row mb-4">
            <FilterSelect
              options={[
                { value: "day", label: "Dia" },
                { value: "week", label: "Setmana" },
                { value: "month", label: "Mes" },
              ]}
              selectedValue={selectedFilter}
              onChange={(option) => handleFilterChange(option.value)}
            />
            {selectedFilter && selectedFilter !== "day" && (
              <div className="mb-4">
                <MultiSelect
                  options={uniqueDates}
                  selectedValues={selectedDates}
                  onChange={setSelectedDates}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col justify-center space-y-6 md:space-y-8 lg:space-y-10">
            <div className="flex justify-center">
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="pie"
                width="100%"
              />
            </div>

            <div className="flex flex-row items-start m-4 gap-2">
              {selectedFilter === "day" ? (
                <>
                  <button
                    onClick={handleExportByDay}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel inclou speakers
                  </button>
                  <button
                    onClick={handleExportStatsExcludingTopSpeaker}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel exclou top speaker
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={handleExportByWeekOrMonth}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel inclou speakers
                  </button>
                  <button
                    onClick={handleExportExcludingTopSpeaker}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel exclou top speaker
                  </button>
                </>
              )}
            </div>

            <div className="flex justify-center">
              <StackedChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
            <div className="flex justify-center">
              <HeatmapChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Charts;
